<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{$t('createAdmins')}}</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button variant="info" @click="create">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered :items="administrators" :fields="columns" :filter="filter">
      <template slot="actions" slot-scope="administrator">
        <div class="w-100 text-center">
          <b-button variant="danger" @click="openModalDestroy(administrator.item)"><i class="fa fa-trash"></i></b-button>
        </div>
      </template>
    </b-table>
    <b-modal ref="modalAdd" :title="$t('createAdmins')">
      <b-form @submit.prevent="findUserById">
        <div class="form-group">
          <label for="id">{{$t('enterUserId')}}</label>
          <b-form-input v-model="userId"  />
        </div>
      </b-form>
      <div slot="modal-footer">
        <b-button @click="$refs.modalAdd.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="primary" @click="findUserById" class="ml-3">{{$t('search')}}</b-button>
      </div>
    </b-modal>
    <b-modal ref="modalDetails" :title="$t('createAdmins')">
      <h6>{{$t('details')}}</h6>
      <hr>
      <p>{{$t('username')}}: <strong>{{username}}</strong></p>
      <p>Nick: <strong>{{nick}}</strong></p>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDetails.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="primary" @click="save" class="ml-3">{{$t('create')}}</b-button>
      </div>
    </b-modal>
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{username}}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="destroy" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      filter: '',
      userId: null,
      username: '',
      nick: '',
      columns: [
        { key: 'username', label: this.$t('username'), sortable: true },
        { key: 'nick', label: 'Nick', sortable: true },
        { key: 'actions', label: '' }
      ],
      console: this.$route.params.console
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'administrators'
    ])
  },
  mounted() {
    document.title = `${this.$t('createAdmins')} - ITFA`
    if (this.administrators.length < 1) {
      this.fetchData()
    }
  },
  methods: {
    create () {
      this.$refs.modalAdd.show()
      this.$store.dispatch('SET_SLIDER', {})
    },
    openModalDestroy (administrator) {
      this.userId = administrator.id
      this.username = administrator.username
      this.$refs.modalDelete.show()
    },
    destroy () {
      const path = `auth/${this.lang}/${this.console}/administrator/delete/${this.userId}`
      this.$axios.delete(path).then(() => {
        this.$store.dispatch('DELETE_ADMINISTRATOR', this.userId)
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$refs.modalDelete.hide()
      })
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/administrator/all`
      this.$axios.get(path).then((response) => {
        this.$store.dispatch('SET_ADMINISTRATORS', response.data.data)
      })
    },
    findUserById () {
      const path = `auth/${this.lang}/${this.console}/administrator/find/${this.userId}`
      this.$axios.get(path).then((response) => {
        const data = response.data.data
        this.nick = data.nick
        this.username = data.username
        this.$refs.modalAdd.hide()
        this.$refs.modalDetails.show()
      })
    },
    save () {
      const path = `auth/${this.lang}/${this.console}/administrator/add`
      this.$axios.post(path, { id: this.userId }).then(() => {
        const administrator = {
          id: this.userId,
          username: this.username,
          nick: this.nick
        }
        this.$store.dispatch('ADD_ADMINISTRATOR', administrator)
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$refs.modalDetails.hide()
      })
    }
  }
}
</script>
